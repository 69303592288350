<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500"
      max-height="350"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("associateTicket") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider />
        <v-row no-gutters class="mx-4 mt-6 mb-4" justify="center">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            :label="$t('searchticketNumber')"
            single-line
            hide-details
            flat
            solo
            dense
            background-color="grey lighten-4"
            class="rounded-lg mt-1"
            clear-icon="mdi-close-circle"
            clearable
            @keyup.enter="searchTickets"
          >
          </v-text-field>
        </v-row>
        <v-data-table
          v-model="selectedTickets"
          :height="$vuetify.breakpoint.mdAndUp ? '60vh' : '75vh'"
          color="primary"
          class="elevation-0 pa-0 ml-4 mb-4"
          :items-per-page="!$vuetify.breakpoint.mdAndUp ? -1 : 15"
          item-key="ticket_id"
          :headers="headers"
          disable-sort
          :options.sync="options"
          :search="search"
          :items="ticketList"
          checkbox-color="primary"
          item-value="ticket_number"
          :hide-default-header="!$vuetify.breakpoint.mdAndUp"
          show-select
          :loading="fetchingTickets"
          :loading-text="$t('loading')"
          :server-items-length="totalItems"
          :footer-props="{
            'items-per-page-options': perPageChoices,
          }"
          @item-selected="onItemSelected"
        >
          <template v-slot:[`item.ticket_number`]="{ item }">
            <v-row class="text-subtitle-2 font-weight-regular ma-0">
              {{ item.ticket_number }}</v-row
            >
          </template>
        </v-data-table>
        <v-row class="mb-2" v-if="!$vuetify.breakpoint.smAndDown">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            color="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            :disabled="!isDifferent"
            @click="submitDialog"
            ><span class="white--text text-none">{{ $t("submit") }}</span>
          </v-btn>
        </v-card-actions>
        <v-bottom-navigation
          v-if="$vuetify.breakpoint.smAndDown"
          fixed
          horizontal
          height="70"
          class="px-2 py-4"
          ><v-row justify="space-between" no-gutters>
            <v-btn
              color="primary"
              outlined
              class="mr-2 text-none rounded-lg elevation-0"
              width="50%"
              @click="closeDialog"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              class="text-none font-weight-regular grey--text rounded-lg elevation-0"
              width="50%"
              depressed
              :disabled="!isDifferent"
              @click="submitDialog"
              ><span class="white--text text-none">{{ $t("submit") }}</span>
            </v-btn>
          </v-row></v-bottom-navigation
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { bus } from "@/main";
import api from "../../../store/api";
const cloneDeep = (a) => JSON.parse(JSON.stringify(a));
export default {
  data() {
    return {
      search: "",
      selectedTickets: [],
      updatedTicketList: [],
      isDifferent: false,
      headers: [
        { text: "Ticket Number", align: "start", value: "ticket_number" },
      ],
      options: {
        sortDesc: [true],
        sortBy: ["created_at"],
        page: 1,
        itemsPerPage: 10,
      },
      ticketList: [],
      totalItems: 0,
      perPageChoices: [10, 25, 50, 100],
      fetchingTickets: false,
      initiallySelectedTickets: []
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    projectDetails: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.getTicketList();
  },
  watch: {
    selectedTickets: {
      handler() {
        this.isDifferent = !this.areArraysOfObjectsEqual(
          this.selectedTickets,
          this.updatedTicketList,
        );
      },
    },
    options: {
      handler(newVal, oldVal) {
        if (
          newVal.itemsPerPage === oldVal.itemsPerPage &&
          newVal.page === oldVal.page &&
          newVal.sortBy[0] === oldVal.sortBy[0] &&
          newVal.sortDesc[0] === oldVal.sortDesc[0] &&
          !this.ticketSuccessAlert
        ) {
          return;
        }
        this.timeOut && clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
          this.getTicketList();
        }, 200);
      },
      deep: true,
    },
    dialog(newVal) {
      if (newVal) {
        this.options.page = 1;
        this.getTicketList();
        this.initiallySelectedTickets = this.selectedTickets;
        console.log(this.initiallySelectedTickets)
      }
    },
  },
  methods: {
    updateSelectedTickets(selected) {
      this.selectedTickets = [...new Set([...this.selectedTickets, ...selected])];
    },
    onItemSelected({ item, value }) {
      if (value) {
        this.selectedTickets.push(item);
      } else {
        this.selectedTickets = this.selectedTickets.filter(
          (selectedItem) => selectedItem.ticket_id !== item.ticket_id
        );
      }
    },
    searchTickets() {
      this.options.page = 1;
      this.getTicketList();
    },
    toggleSelected(item) {
      if (item.selected) {
        this.selectedTickets.push(item);
      } else {
        this.selectedTickets = this.selectedTickets.filter((item) => {
          return item.ticket_number !== item.ticket_number;
        });
      }
    },
    setAssociatedTicket(associatedTicketList) {
      this.selectedTickets = associatedTicketList;
      this.setSelectedAssociatedTicket(associatedTicketList);
    },
    setSelectedAssociatedTicket(associatedTicketList) {
      this.updatedTicketList = cloneDeep(associatedTicketList);
      this.isDifferent = false;
    },
    areArraysOfObjectsEqual(a1, a2) {
      return (
        a1.length === a2.length &&
        a1.every((o, idx) => this.objectsEqual(o, a2[idx]))
      );
    },
    objectsEqual(obj1, obj2) {
      let keyExist = false;
      for (const [key, value] of Object.entries(obj1)) {
        if (Object.keys(obj2).some((e) => e == key)) {
          keyExist = true;
        }
      }
      return keyExist;
    },
    closeDialog() {
      this.search = "";
      this.$emit("closeDialog");
    },
    async submitDialog() {
      const payload = {};
      console.log(this.selectedTickets, this.initiallySelectedTickets)
      const allSelectedTickets = [...new Set([...this.selectedTickets, ...this.initiallySelectedTickets])];
      let associateTicketList = allSelectedTickets.map((ticket) => {
        return ticket.ticket_id;
      });

      if (this.projectDetails && this.projectDetails.project_id)
        payload.project_id = this.projectDetails.project_id;

      if (associateTicketList && associateTicketList.length !== 0) {
        payload.ticket_ids = associateTicketList.join(",");
      } else {
        payload.ticket_ids = "";
      }

      const ticketUpdateResp = await this.$store.dispatch(
        "updateProjectTickets",
        payload,
      );
      if (ticketUpdateResp.status === "error") {
        console.log("error in Associate Ticket");
      } else {
        this.search = "";
        bus.$emit("RefreshTicketTable");
        this.$emit("associateTicketSubmit");
      }
    },
    async getTicketFromCenter() {
      const apiFilter = Object.assign({}, this.filters);
      if (!this.$vuetify.breakpoint.smAndDown) {
        apiFilter.page = this.options.page;
        apiFilter.limit = this.options.itemsPerPage;
        apiFilter.sortBy = this.options.sortBy[0];
        apiFilter.sortDesc = this.options.sortDesc[0];
      }
      if (this.search) {
        apiFilter.center_ticket_id = this.search;
      }
      return await api.ticket.getTickets(apiFilter);
    },
    async getTicketList() {
      this.fetchingTickets = true;
      const ticketData = await this.getTicketFromCenter();
      let ticketListFiltered = [];
      this.totalItems = parseInt(ticketData.totalCount);
      if (ticketData && ticketData.tickets) {
        let associatedTicketList = [];
        let ticketList = Object.entries(ticketData.tickets).map(
          ([key, value]) => {
            let ticketNumber = "";
            let ticketObj = {};
            if (value.revision)
              ticketNumber = `${value.center_ticket_id}-${value.revision}`;
            else ticketNumber = `${value.center_ticket_id}`;
            ticketObj = {
              ticket_number: ticketNumber,
              ticket_id: value.ticket_id,
              center_ticket_id: value.center_ticket_id,
              project_id: value.project_id,
            };
            if (
              this.projectDetails &&
              value.project_id &&
              this.projectDetails.project_id &&
              value.project_id === this.projectDetails.project_id
            ) {
              ticketObj.selected = true;
              associatedTicketList.push(ticketObj);
            } else {
              ticketObj.selected = false;
            }
            return ticketObj;
          },
        );
        
        this.setAssociatedTicket(associatedTicketList);
        ticketListFiltered = ticketList.filter((ticket) => {
          return (
            !ticket.center_ticket_id.startsWith("S") &&
            (!ticket.project_id ||
              !(
                this.projectDetails &&
                ticket.project_id &&
                this.projectDetails.project_id &&
                ticket.project_id !== this.projectDetails.project_id
              ))
          );
        });
      }
      this.ticketList = ticketListFiltered;
      this.fetchingTickets = false;
    },
  },
};
</script>
<style lang="scss">
.v-data-table > .v-data-table__wrapper > table {
  .v-data-table__mobile-table-row {
    margin-right: 10px;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }

  .v-data-table__mobile-row {
    margin-left: 12px;
    display: inline-flex;
    .v-data-table__mobile-row__header {
      display: none;
    }
  }
}
</style>
